import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import winterBG from 'assets/images/tournaments-image-6.jpg';
import easterBG from 'assets/images/tournaments-image.jpg';
import summerBG from 'assets/images/tournaments-image-4.jpg';
import autumnBG from 'assets/images/tournaments-image-7.jpg';
import { TournamentCard } from 'components/Cards';
import { get } from 'api';
import moment from 'moment';

const UpcomingTournaments = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeTournaments, setActiveTournaments] = useState([]);

  useEffect(() => {
    const fetchActiveTournaments = async () => {
      const response = await get('/tournaments/active-tournaments');
      const data = await response.json();
      const sortedData = data.sort((a, b) => {
        return moment(b.start_date).diff(moment(a.start_date));
      });
      setActiveTournaments(sortedData);
    };
    fetchActiveTournaments();
  }, []);

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={'48px'}
      >
        <Box>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: '12px' }}
          >
            {t('upcomingTournamentsTitle')}
          </Typography>
          <Typography variant="body1" color={theme.palette.darkGrey[600]}>
            {t('upcomingTournamentsSubtitle')}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={{ xs: '48px', sm: '32px' }}>
        {activeTournaments.map((item, i) => {
          const tournamentBG = (() => {
            if (item.tournament_name.includes('ŽIEMA')) {
              return winterBG;
            } else if (item.tournament_name.includes('PAVASARIS')) {
              return easterBG;
            } else if (item.tournament_name.includes('VASARA')) {
              return summerBG;
            } else if (item.tournament_name.includes('RUDUO')) {
              return autumnBG;
            } else {
              return winterBG;
            }
          })();
          return (
            <Grid item xs={12} sm={6} md={5} key={i}>
              <TournamentCard
                id={item.id}
                type={item.tournament_type}
                tournament={item.tournament_name}
                image={tournamentBG}
                title={item.tournament_name}
                date={item.start_day}
                location={item.city}
                brief={t('tournamentCardBrief')}
                view={'system'}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default UpcomingTournaments;
