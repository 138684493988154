import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import { get, getUserIdFromToken } from 'api';
import { PrimaryTab } from 'components/Tabs';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import calendar from 'assets/icons/calendar-check-02.svg';

const GroupTable = ({ level, filter }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getUserIdFromToken();
  const { id } = useParams();
  const [activeLevel, setActiveLevel] = useState('light');
  const [groups, setGroups] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showTooltip, setShowTooltip] = useState({});

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.base.white,
      color: theme.palette.darkGrey[700],
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      fontSize: 12,
      fontFamily: 'Inter, sans-serif',
      padding: '8px 12px',
      borderRadius: '8px',
    },
  }));

  useEffect(() => {
    if (level) {
      setActiveLevel(level.toLowerCase());
    }
  }, [level]);

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await get(`/tournaments/tournamentsreg/${id}`);
      const data = await response.json();
      const filtered = data.filter(
        (item) => item.tournament_gender === filter.gender,
      );
      setAllData(filtered);
      const activeLevel = level ? level.toLowerCase() : 'light';
      setActiveLevel(activeLevel);
    };
    fetchGroups();
  }, [id, token, filter.gender]);

  useEffect(() => {
    const filteredGroups = allData.filter(
      (item) =>
        item.tournament_level.toLowerCase() === activeLevel &&
        item.group_name !== null,
    );
    setFilteredData(filteredGroups);
  }, [activeLevel, allData]);

  const divideByGroups = useCallback(() => {
    const groups = {
      A: [],
      B: [],
      C: [],
      D: [],
      E: [],
      F: [],
      G: [],
      H: [],
      I: [],
      J: [],
      K: [],
      L: [],
      M: [],
      N: [],
      O: [],
      P: [],
      Q: [],
      R: [],
    };

    filteredData.forEach((item) => {
      groups[item.group_name].push(item);
    });

    setGroups(Object.values(groups));
  }, [filteredData]);

  useEffect(() => {
    if (filteredData.length > 0) {
      divideByGroups();
      setLoaded(true);
    }
  }, [filteredData, divideByGroups]);

  const groupSize5 = 5;
  const groupSize6 = 6;
  const groupSize7 = 7;

  const isWinner = (rez) => {
    if (rez === null) {
      return false;
    }
    const sets = rez.split(' ');
    let playerWins = 0;
    let opponentWins = 0;
    sets.forEach((set) => {
      const scores = set.split(':');
      const playerScore = parseInt(scores[0]);
      const opponentScore = parseInt(scores[1]);
      if (playerScore > opponentScore) {
        playerWins++;
      } else if (playerScore < opponentScore) {
        opponentWins++;
      }
    });
    return playerWins > opponentWins;
  };

  const updatedRez = (rez, index, i, group_name) => {
    if (rez === null) {
      return '';
    }

    if (rez.includes('202')) {
      return (
        <LightTooltip
          title={rez}
          placement={'top'}
          open={showTooltip[`${group_name}-${index}-${i}`] || false}
          onOpen={() =>
            setShowTooltip((prevState) => ({
              ...prevState,
              [`${group_name}-${index}-${i}`]: true,
            }))
          }
          onClose={() =>
            setShowTooltip((prevState) => ({
              ...prevState,
              [`${group_name}-${index}-${i}`]: false,
            }))
          }
        >
          <Box
            component={'img'}
            src={calendar}
            marginLeft={'8px'}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              setShowTooltip((prevState) => ({
                ...prevState,
                [`${group_name}-${index}-${i}`]:
                  !prevState[`${group_name}-${index}-${i}`],
              }))
            }
            alt="calendar icon"
          />
        </LightTooltip>
      );
    } else {
      const parts = rez.split(' ');
      const regex = /\((\d+)\)/g;
      const superscriptParts = parts.map((part) =>
        part.replace(regex, '<sup>$1</sup>'),
      );
      const superscriptRezultatas = superscriptParts.join(' ');
      return (
        <Typography
          variant="body5"
          fontWeight={isWinner(rez) ? 700 : 500}
          color={
            isWinner(rez)
              ? theme.palette.darkGrey[700]
              : theme.palette.darkGrey[600]
          }
          dangerouslySetInnerHTML={{ __html: superscriptRezultatas }}
        />
      );
    }
  };

  const createCells = (i, groupLength, player) => {
    const {
      M12,
      M13,
      M14,
      M15,
      M16,
      M17,
      M23,
      M24,
      M25,
      M26,
      M27,
      M34,
      M35,
      M36,
      M37,
      M45,
      M46,
      M47,
      M56,
      M57,
      M67,
      points,
      group_name,
      group_place,
    } = player;
    const scores = [
      [null, M12, M13, M14, M15, M16, M17],
      [M12, null, M23, M24, M25, M26, M27],
      [M13, M23, null, M34, M35, M36, M37],
      [M14, M24, M34, null, M45, M46, M47],
      [M15, M25, M35, M45, null, M56, M57],
      [M16, M26, M36, M46, M56, null, M67],
      [M17, M27, M37, M47, M57, M67, null],
    ];

    return (
      <>
        {scores[i] &&
          scores[i].map((score, index) => {
            if (index === i) {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '126px',
                    boxSizing: 'border-box',
                    justifyContent: 'center',
                    borderLeft: `1px solid ${theme.palette.grey[500]}`,
                    backgroundColor: theme.palette.darkGrey[300],
                    padding: '20px 12px',
                    minHeight: '92px',
                  }}
                />
              );
            } else if (index >= groupLength) {
              return '';
            } else {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '126px',
                    boxSizing: 'border-box',
                    justifyContent: 'space-evenly',
                    padding: '20px 12px',
                    minHeight: '92px',
                    borderLeft: `1px solid ${theme.palette.grey[500]}`,
                    alignItems: 'center',
                  }}
                >
                  {updatedRez(scores[i][index], index, i, group_name)}
                </Box>
              );
            }
          })}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '126px',
            boxSizing: 'border-box',
            justifyContent: 'center',
            padding: '20px 16px',
            minHeight: '92px',
            borderLeft: `1px solid ${theme.palette.grey[500]}`,
            alignItems: 'center',
            backgroundColor: theme.palette.darkGrey[100],
          }}
        >
          <Typography
            variant="body4"
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            {!points ? '0' : points}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '126px',
            borderBox: 'box-sizing',
            justifyContent: 'center',
            padding: '20px 16px',
            minHeight: '92px',
            borderLeft: `1px solid ${theme.palette.grey[500]}`,
            alignItems: 'center',
            backgroundColor: theme.palette.darkGrey[100],
          }}
        >
          <Typography
            variant="body4"
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            {!group_place ? '-' : group_place}
          </Typography>
        </Box>
      </>
    );
  };

  if (!loaded) {
    return null;
  }

  return (
    <Box>
      <Box
        maxWidth={'700px'}
        marginBottom={'48px'}
        display={{ xs: 'flex', sm: 'inline-flex' }}
        justifyContent={'space-between'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {['super light', 'light', 'middle', 'power', 'hard'].map((level) => {
          const hasLevel = allData.some(
            (item) => item.tournament_level.toLowerCase() === level,
          );

          return (
            hasLevel && (
              <PrimaryTab
                key={level}
                activeTab={activeLevel}
                setActiveTab={setActiveLevel}
                tabName={level}
                pageName={'groupTable'}
              />
            )
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {groups.map((group, index) => {
          const groupNames = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
          ];
          const groupName = groupNames[index] || '';

          if (group.length === 0) {
            return '';
          }

          const groupSize = group.length;
          const tableWidth = groupSize * 126 + 280 + 126 + 126 + 4;
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                maxWidth: `${tableWidth}px`,
                overflowX: 'auto',
                marginBottom: '32px',
                paddingBottom: '4px',
                '&::-webkit-scrollbar': {
                  height: '6px',

                  backgroundColor: theme.palette.grey[200],
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.grey[400],
                  borderRadius: '8px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: '280px',
                      paddingLeft: '16px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      color={theme.palette.darkGrey[700]}
                    >
                      {t('group')} {groupName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      width: 'auto',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '126px',
                        justifyContent: 'center',
                        minHeight: '3rem',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '126px',
                        justifyContent: 'center',
                        minHeight: '3rem',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '126px',
                        justifyContent: 'center',
                        minHeight: '3rem',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '126px',
                        justifyContent: 'center',
                        minHeight: '3rem',
                      }}
                    />
                    {group.length < groupSize5 ? (
                      ''
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '126px',

                          justifyContent: 'center',
                          minHeight: '3rem',
                        }}
                      />
                    )}
                    {group.length < groupSize6 ? (
                      ''
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '126px',

                          justifyContent: 'center',
                          minHeight: '3rem',
                        }}
                      />
                    )}
                    {group.length < groupSize7 ? (
                      ''
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '126px',

                          justifyContent: 'center',
                          minHeight: '3rem',
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '126px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body4"
                        fontWeight={500}
                        color={theme.palette.darkGrey[700]}
                      >
                        {t('points')}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '126px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="body4"
                        fontWeight={500}
                        color={theme.palette.darkGrey[700]}
                      >
                        {t('place')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: `2px solid ${theme.palette.darkGrey[400]}`,
                    borderRadius: '16px',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  {group.map((player, i) => {
                    const isLastItem = i === group.length - 1;
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '100%',
                          minHeight: '92px',

                          borderBottom: isLastItem
                            ? 'none'
                            : `1px solid ${theme.palette.grey[500]}`,
                        }}
                        key={player.user_id}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '280px',
                            padding: '20px 16px',
                            minHeight: '92px',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            backgroundColor: theme.palette.darkGrey[100],
                          }}
                        >
                          <Box
                            sx={{
                              width: '52px',
                              height: '52px',
                              flexShrink: 0,
                            }}
                          >
                            <Box
                              component={'img'}
                              src={
                                player.image
                                  ? `https://backend.tenisopartneris.lt/public/images/${player.image}`
                                  : avatarWhite
                              }
                              alt="avatar"
                              sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                          </Box>
                          <Box sx={{ marginLeft: '16px', maxWidth: '200px' }}>
                            {' '}
                            {/* Set a maxWidth to control text overflow */}
                            <Box
                              component={'a'}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline',
                                  textDecorationColor:
                                    theme.palette.darkGrey[700],
                                },
                              }}
                              href={`${window.location.origin}/info/${player.user_id}`}
                            >
                              <Typography
                                variant="body4"
                                fontWeight={600}
                                color={theme.palette.darkGrey[700]}
                                sx={{
                                  whiteSpace: 'normal',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {player.first_name} {player.last_name}
                              </Typography>
                            </Box>
                            <Box
                              component={'a'}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline',
                                  textDecorationColor:
                                    theme.palette.darkGrey[700],
                                },
                              }}
                              href={`https://tenisopartneris.lt/info/${player.doubles_partner}`}
                            >
                              <Typography
                                variant="body4"
                                fontWeight={600}
                                color={theme.palette.darkGrey[700]}
                                sx={{
                                  whiteSpace: 'normal', // Allow text to wrap
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 1, // Limit to 1 line
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {player.partner_first_name}{' '}
                                {player.partner_last_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {createCells(i, group.length, player)}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default GroupTable;
