export const mainTableDates = {
  TOP64: {
    TOP64: '2024-04-28',
    TOP32: '2024-05-07',
    TOP16: '2024-05-15',
    TOP8: '2024-05-23',
    TOP4: '2024-05-31',
    FINAL: '2024-06-01',
  },
  TOP32: {
    TOP32: '2024-07-24',
    TOP16: '2024-08-02',
    TOP8: '2024-08-11',
    TOP4: '2024-08-21',
    FINAL: '2024-08-31',
  },
  TOP16: {
    TOP16: '2024-07-30',
    TOP8: '2024-08-11',
    TOP4: '2024-08-21',
    FINAL: '2024-08-31',
  },
  TOP8: {
    TOP8: '2024-08-03',
    TOP4: '2024-08-17',
    FINAL: '2024-08-31',
  },
  TOP4: {
    TOP4: '2024-08-11',
    FINAL: '2024-08-31',
  },
};

export const loserTableDates = {
  TOP64: {
    TOP64: '2024-04-28',
    TOP32: '2024-05-07',
    TOP16: '2024-05-15',
    TOP8: '2024-05-23',
    TOP4: '2024-05-31',
    FINAL: '2024-06-01',
  },
  TOP32: {
    TOP32: '2024-07-24',
    TOP16: '2024-08-02',
    TOP8: '2024-08-11',
    TOP4: '2024-08-21',
    FINAL: '2024-08-31',
  },
  TOP16: {
    TOP16: '2024-07-30',
    TOP8: '2024-08-11',
    TOP4: '2024-08-21',
    FINAL: '2024-08-31',
  },
  TOP8: {
    TOP8: '2024-08-03',
    TOP4: '2024-08-17',
    FINAL: '2024-08-31',
  },
  TOP4: {
    TOP4: '2024-08-11',
    FINAL: '2024-08-31',
  },
};

export const winnerPoints = {
  Grupės: {
    'Super Light': 1,
    Light: 2,
    Middle: 3,
    Power: 4,
    Hard: 5,
  },
  Pagrindas: {
    'Super Light': {
      P1_rez: 2,
      P2_rez: 3,
      P3_rez: 4,
      P4_rez: 5,
      P5_rez: 1,
      P6_rez: 21,
      P7_rez: 11,
    },
    Light: {
      P1_rez: 4,
      P2_rez: 6,
      P3_rez: 8,
      P4_rez: 10,
      P5_rez: 2,
      P6_rez: 27,
      P7_rez: 17,
    },
    Middle: {
      P1_rez: 6,
      P2_rez: 9,
      P3_rez: 12,
      P4_rez: 15,
      P5_rez: 3,
      P6_rez: 33,
      P7_rez: 23,
    },
    Power: {
      P1_rez: 8,
      P2_rez: 12,
      P3_rez: 16,
      P4_rez: 20,
      P5_rez: 4,
      P6_rez: 39,
      P7_rez: 29,
    },
    Hard: {
      P1_rez: 10,
      P2_rez: 15,
      P3_rez: 20,
      P4_rez: 25,
      P5_rez: 5,
      P6_rez: 45,
      P7_rez: 35,
    },
  },
  Paguoda: {
    'Super Light': {
      P6_rez: 11,
      default: 1,
    },
    Light: {
      P6_rez: 17,
      default: 2,
    },
    Middle: {
      P6_rez: 23,
      default: 3,
    },
    Power: {
      P6_rez: 29,
      default: 4,
    },
    Hard: {
      P6_rez: 35,
      default: 5,
    },
  },
};

export const losersPointsMap = {
  Grupės: 0,
  Pagrindas: {
    'Super Light': {
      P1_rez: 1,
      P2_rez: 2,
      P3_rez: 3,
      P4_rez: 4,
      P5_rez: 0,
      P6_rez: 15,
      P7_rez: 5,
    },
    Light: {
      P1_rez: 2,
      P2_rez: 4,
      P3_rez: 6,
      P4_rez: 8,
      P5_rez: 0,
      P6_rez: 20,
      P7_rez: 10,
    },
    Middle: {
      P1_rez: 3,
      P2_rez: 6,
      P3_rez: 9,
      P4_rez: 12,
      P5_rez: 0,
      P6_rez: 25,
      P7_rez: 15,
    },
    Power: {
      P1_rez: 4,
      P2_rez: 8,
      P3_rez: 12,
      P4_rez: 16,
      P5_rez: 0,
      P6_rez: 30,
      P7_rez: 20,
    },
    Hard: {
      P1_rez: 5,
      P2_rez: 10,
      P3_rez: 15,
      P4_rez: 20,
      P5_rez: 0,
      P6_rez: 35,
      P7_rez: 25,
    },
  },
  Paguoda: {
    'Super Light': {
      P6_rez: 0,
    },
    Light: {
      P6_rez: 10,
    },
    Middle: {
      P6_rez: 15,
    },
    Power: {
      P6_rez: 20,
    },
    Hard: {
      P6_rez: 25,
    },
    default: 0,
  },
};

export const matchNumberMapping = {
  P1_rez: { winner: 'P1_winner', nextMatch: 'P2_rez', nextGroup: 'P2_group' },
  P2_rez: { winner: 'P2_winner', nextMatch: 'P3_rez', nextGroup: 'P3_group' },
  P3_rez: { winner: 'P3_winner', nextMatch: 'P4_rez', nextGroup: 'P4_group' },
  P4_rez: { winner: 'P4_winner', nextMatch: 'P5_rez', nextGroup: 'P5_group' },
  P5_rez: { winner: 'P5_winner', nextMatch: 'P6_rez', nextGroup: 'P6_group' },
  P6_rez: { winner: 'P6_winner', nextMatch: 'P7_rez', nextGroup: 'P7_group' },
  P7_rez: { winner: 'P7_winner', nextMatch: 'P7_rez', nextGroup: 'P7_group' },
};

export const registrationPointsMap = {
  'Super Light': 5,
  Light: 10,
  Middle: 15,
  Power: 20,
  Hard: 25,
};

export const tournamentStageMap = {
  Grupės: 'Grupės',
  Pagrindas: {
    P1_rez: 'Pagrindas TOP 64',
    P2_rez: 'Pagrindas TOP 32',
    P3_rez: 'Pagrindas TOP 16',
    P4_rez: 'Pagrindas TOP 8',
    P5_rez: 'Pagrindas Pusfinalis',
    P6_rez: 'Pagrindas Finalas',
    P7_rez: 'Pagrindas Dėl 3 vietos',
  },
  Paguoda: {
    P1_rez: 'Paguoda TOP 64',
    P2_rez: 'Paguoda TOP 32',
    P3_rez: 'Paguoda TOP 16',
    P4_rez: 'Paguoda TOP 8',
    P5_rez: 'Paguoda Pusfinalis',
    P6_rez: 'Paguoda Finalas',
    P7_rez: 'Paguoda Dėl 3 vietos',
  },
};

export const loserPositionMap = {
  PF1: 'F3',
  PF2: 'F3',
  PF3: 'F4',
  PF4: 'F4',
};

export const winnerPositionMap = {
  T1: 'S1',
  T2: 'S1',
  T3: 'S2',
  T4: 'S2',
  T5: 'S3',
  T6: 'S3',
  T7: 'S4',
  T8: 'S4',
  T9: 'S5',
  T10: 'S5',
  T11: 'S6',
  T12: 'S6',
  T13: 'S7',
  T14: 'S7',
  T15: 'S8',
  T16: 'S8',
  T17: 'S9',
  T18: 'S9',
  T19: 'S10',
  T20: 'S10',
  T21: 'S11',
  T22: 'S11',
  T23: 'S12',
  T24: 'S12',
  T25: 'S13',
  T26: 'S13',
  T27: 'S14',
  T28: 'S14',
  T29: 'S15',
  T30: 'S15',
  T31: 'S16',
  T32: 'S16',
  T33: 'S17',
  T34: 'S17',
  T35: 'S18',
  T36: 'S18',
  T37: 'S19',
  T38: 'S19',
  T39: 'S20',
  T40: 'S20',
  T41: 'S21',
  T42: 'S21',
  T43: 'S22',
  T44: 'S22',
  T45: 'S23',
  T46: 'S23',
  T47: 'S24',
  T48: 'S24',
  T49: 'S25',
  T50: 'S25',
  T51: 'S26',
  T52: 'S26',
  T53: 'S27',
  T54: 'S27',
  T55: 'S28',
  T56: 'S28',
  T57: 'S29',
  T58: 'S29',
  T59: 'S30',
  T60: 'S30',
  T61: 'S31',
  T62: 'S31',
  T63: 'S32',
  T64: 'S32',
  S1: 'A1',
  S2: 'A1',
  S3: 'A2',
  S4: 'A2',
  S5: 'A3',
  S6: 'A3',
  S7: 'A4',
  S8: 'A4',
  S9: 'A5',
  S10: 'A5',
  S11: 'A6',
  S12: 'A6',
  S13: 'A7',
  S14: 'A7',
  S15: 'A8',
  S16: 'A8',
  S17: 'A9',
  S18: 'A9',
  S19: 'A10',
  S20: 'A10',
  S21: 'A11',
  S22: 'A11',
  S23: 'A12',
  S24: 'A12',
  S25: 'A13',
  S26: 'A13',
  S27: 'A14',
  S28: 'A14',
  S29: 'A15',
  S30: 'A15',
  S31: 'A16',
  S32: 'A16',
  A1: 'K1',
  A2: 'K1',
  A3: 'K2',
  A4: 'K2',
  A5: 'K3',
  A6: 'K3',
  A7: 'K4',
  A8: 'K4',
  A9: 'K5',
  A10: 'K5',
  A11: 'K6',
  A12: 'K6',
  A13: 'K7',
  A14: 'K7',
  A15: 'K8',
  A16: 'K8',
  K1: 'PF1',
  K2: 'PF1',
  K3: 'PF2',
  K4: 'PF2',
  K5: 'PF3',
  K6: 'PF3',
  K7: 'PF4',
  K8: 'PF4',
  PF1: 'F1',
  PF2: 'F1',
  PF3: 'F2',
  PF4: 'F2',
};
