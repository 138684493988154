export const WomenLevels = [
  {
    value: 'Super Light',
    label: 'Super Light (iki 2.0)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Light',
    label: 'Light (NTRP 2.5)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Middle',
    label: 'Middle (NTRP 3.0)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Power',
    label: 'Power (NTRP 3.5)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Hard',
    label: 'Hard (NTRP 4.0)',
    kaunasOn: false,
    vilniusOn: false,
  },
];

export const MenLevels = [
  {
    value: 'Super Light',
    label: 'Super Light (iki 2.0)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Light',
    label: 'Light (NTRP 2.5)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Middle',
    label: 'Middle (NTRP 3.0)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Power',
    label: 'Power (NTRP 3.5)',
    kaunasOn: true,
    vilniusOn: true,
  },
  {
    value: 'Hard',
    label: 'Hard (NTRP 4.0)+',
    kaunasOn: false,
    vilniusOn: true,
  },
];

export const MatchLength = [
  { value: '1:00', label: '1:00' },
  { value: '1:30', label: '1:30' },
  { value: '2:00', label: '2:00' },
];

export const Levels = [
  { value: 'Super Light', label: 'Super Light (iki 2.0)' },
  { value: 'Light', label: 'Light (NTRP 2.5)' },
  { value: 'Middle', label: 'Middle (NTRP 3.0)' },
  { value: 'Power', label: 'Power (NTRP 3.5)' },
  { value: 'Hard', label: 'Hard (NTRP 4.0)+' },
];

export const NTRP = [
  { value: '', label: 'Visi' },
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '3.5', label: '3.5' },
  { value: '4', label: '4' },
  { value: '4.5', label: '4.5' },
  { value: '5', label: '5' },
  { value: '5.5', label: '5.5' },
  { value: '6', label: '6' },
  { value: '6.5', label: '6.5' },
  { value: '7', label: '7' },
];

export const fromNTRP = [
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '3.5', label: '3.5' },
  { value: '4', label: '4' },
  { value: '4.5', label: '4.5' },
  { value: '5', label: '5' },
  { value: '5.5', label: '5.5' },
  { value: '6', label: '6' },
  { value: '6.5', label: '6.5' },
  { value: '7', label: '7' },
];

export const toNTRP = [
  { value: '', label: 'Nesvarbu' },
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '3.5', label: '3.5' },
  { value: '4', label: '4' },
  { value: '4.5', label: '4.5' },
  { value: '5', label: '5' },
  { value: '5.5', label: '5.5' },
  { value: '6', label: '6' },
  { value: '6.5', label: '6.5' },
  { value: '7', label: '7' },
];

export const CourtNr = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: 'CC', label: 'Centrinis' },
];

export const MatchType = [
  { value: 'Vienetai', label: 'Vienetai' },
  { value: 'Dvejetai', label: 'Dvejetai' },
  { value: 'MixDvejetai', label: 'Mix Dvejetai' },
];

export const MatchTypePartner = [
  { value: 'any', label: 'Nesvarbu' },
  { value: 'Vienetai', label: 'Vienetai' },
  { value: 'Dvejetai', label: 'Dvejetai' },
  { value: 'MixDvejetai', label: 'Mix Dvejetai' },
];

export const Times = [
  { value: '23:59', label: 'Sutartinis' },
  { value: '06:00', label: '06:00' },
  { value: '06:30', label: '06:30' },
  { value: '07:00', label: '07:00' },
  { value: '07:30', label: '07:30' },
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
];

export const Cities = [
  { value: '', label: 'Visi' },
  { value: 'Vilnius', label: 'Vilnius' },
  { value: 'Kaunas', label: 'Kaunas' },
  { value: 'Klaipėda', label: 'Klaipėda' },
  { value: 'Panevėžys', label: 'Panevėžys' },
  { value: 'Šiauliai', label: 'Šiauliai' },
  { value: 'Alytus', label: 'Alytus' },
  { value: 'Anykščiai', label: 'Anykščiai' },
  { value: 'Biržai', label: 'Biržai' },
  { value: 'Birštonas', label: 'Birštonas' },
  { value: 'Druskininkai', label: 'Druskininkai' },
  { value: 'Elektrėnai', label: 'Elektrėnai' },
  { value: 'Ignalina', label: 'Ignalina' },
  { value: 'Jonava', label: 'Jonava' },
  { value: 'Kaišiadorys', label: 'Kaišiadorys' },
  { value: 'Kelmė', label: 'Kelmė' },
  { value: 'Marijampolė', label: 'Marijampolė' },
  { value: 'Molėtai', label: 'Molėtai' },
  { value: 'Mažeikiai', label: 'Mažeikiai' },
  { value: 'Neringa', label: 'Neringa' },
  { value: 'Nida', label: 'Nida' },
  { value: 'Palanga', label: 'Palanga' },
  { value: 'Prienai', label: 'Prienai' },
  { value: 'Raseiniai', label: 'Raseiniai' },
  { value: 'Šilutė', label: 'Šilutė' },
  { value: 'Širvintos', label: 'Širvintos' },
  { value: 'Tauragė', label: 'Tauragė' },
  { value: 'Telšiai', label: 'Telšiai' },
  { value: 'Trakai', label: 'Trakai' },
  { value: 'Utena', label: 'Utena' },
  { value: 'Ukmergė', label: 'Ukmergė' },
  { value: 'Varėna', label: 'Varėna' },
  { value: 'Vilkaviškis', label: 'Vilkaviškis' },
  { value: 'Visaginas', label: 'Visaginas' },
];

export const Categories = [
  { value: '', label: 'Visos' },
  { value: 'tennisRacquet', label: 'Teniso raketės' },
  { value: 'tennisBalls', label: 'Teniso kamuoliukai' },
  { value: 'tennisClothes', label: 'Teniso apranga' },
  { value: 'tennisShoes', label: 'Teniso bateliai' },
  { value: 'tennisBags', label: 'Teniso krepšiai' },
  { value: 'tennisAccessories', label: 'Teniso aksesuarai' },
  { value: 'tennisCoaching', label: 'Teniso treniruotės' },
  { value: 'tennisStringing', label: 'Stygų tempimas' },
  { value: 'tennisOther', label: 'Kita' },
];

export const Sorting = [
  { value: '', label: 'Nesurūšiuota' },
  { value: 'dateDesc', label: 'Naujausi' },
  { value: 'dateAsc', label: 'Seniausi' },
  { value: 'priceAsc', label: 'Pigiausi' },
  { value: 'priceDesc', label: 'Brangiausi' },
];

export const Condition = [
  { value: '10', label: '10 / 10' },
  { value: '9', label: '9 / 10' },
  { value: '8', label: '8 / 10' },
  { value: '7', label: '7 / 10' },
  { value: '6', label: '6 / 10' },
  { value: '5', label: '5 / 10' },
  { value: '4', label: '4 / 10' },
  { value: '3', label: '3 / 10' },
  { value: '2', label: '2 / 10' },
  { value: '1', label: '1 / 10' },
];

export const ProductCondition = [
  { value: '', label: 'Visos' },
  { value: 'nauja', label: 'Nauja' },
  { value: 'naudota', label: 'Naudota' },
];
